import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/SEO";
import Layout from "../components/layout";
import PageHeader from "../components/pageHeader";
import * as pageStyles from "./page.module.less";

const ErrorPage = (props) => {
  return (
    <Layout>
      <Seo
        title="Page not found"
      />
      <PageHeader
        header="Page not found"
      />
      <Container>
        <Row className={`justify-content-md-center ${pageStyles.pageParentRow} ${pageStyles.pageRow}`}>
          <Col lg={10} md={12}>
            We're really sorry but the page you're looking for doesn't exist! If you're having difficulty finding what you're looking for then please <Link to="/contact/">get in touch</Link>.
          </Col>
        </Row>
      </Container>
    </Layout>
  )
};
export default ErrorPage;